import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsDataSource } from '../../models/professional-services-projects.datasource';
import { ProfessionalServicesService } from '../../professional-services.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TemplatesPopupComponent } from '../../templates/templates-popup/templates-popup.component';
import { Location } from '@angular/common';
import { ServiceEstimateComponentListComponent } from '../../service-estimate-components/service-estimate-component-list/service-estimate-component-list.component';

@Component({
  selector: 'app-parent-estmiate-detail',
  templateUrl: './parent-estmiate-detail.component.html',
  styleUrls: ['./parent-estmiate-detail.component.scss']
})
export class ParentEstmiateDetailComponent implements OnInit {
  @ViewChild(ServiceEstimateComponentListComponent, { static: false }) componentList: ServiceEstimateComponentListComponent;
  serviceEstimate: any;
  serviceEstimateId: string;
  serviceEstimateComponents: ProjectsDataSource;


  constructor(private route: ActivatedRoute, private psService: ProfessionalServicesService, private dialog: MatDialog, private location: Location) { }

  ngOnInit() {
    this.route.params
      .subscribe(param => {
        this.serviceEstimateId = param['serviceEstimateId'];
        this.psService.getServiceEstimate(this.serviceEstimateId)
          .subscribe(res => {
            this.serviceEstimate = res;
          });
      });
  }
  createChangeOrderFromTemplate() {
    const dialogref = this.dialog.open(TemplatesPopupComponent);
    dialogref.afterClosed()
      .subscribe(resp => {
        this.psService.createChangeOrderFromTemplate(resp['data'], this.serviceEstimateId)
          .subscribe(res => {
            this.componentList.getTableData();
          });
      });
  }
  createServiceEstimateFromTemplate() {
    const dialogref = this.dialog.open(TemplatesPopupComponent);
    dialogref.afterClosed()
      .subscribe(resp => {
        this.psService.createServiceEstimateFromTemplate(resp['data'], this.serviceEstimateId)
          .subscribe(res => {
            this.componentList.getTableData();
          });
      });
  }

}
