import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ProfessionalServicesService } from '../../professional-services.service';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../../admin/admin.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { TemplateComponentsDataSource } from '../../models/template-components.datasource';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { RoleService } from '../../../shared/services/role.service';
import { CloningPopupComponent } from '../../../shared/components/cloning-popup/cloning-popup.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss']
})
export class TemplatesListComponent implements OnInit {

  @ViewChild(MatPaginator,{ static: true }) paginator: MatPaginator;
  @ViewChild(MatSort,{ static: true }) sort: MatSort;
  @ViewChild(MatTable,{ static: true }) table: MatTable<any>;

  @Input() serviceEstimates: Array<any>;
  @Input() isPopup: boolean;
  @Output() selectedTemplate: EventEmitter<any> = new EventEmitter<any>();
  displayedColumns: Array<string>;
  dataSource: TemplateComponentsDataSource;
  seId: string;
  isDateAscending: boolean;
  isNameAscending: boolean;
  pageSize: number;
  employees: any[];
  currentUser: any;
  isDirector: boolean;
  user: AccountInfo | null;
  filterForm: UntypedFormGroup;
  filteredTemplates: any;

  constructor(private projectService: ProfessionalServicesService,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private authService: MsalService,
    private roleService: RoleService,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.filterForm = this.fb.group({
      templateName: [''],
      employee: [],
    });
    this.user = this.authService.instance.getActiveAccount();
    this.isDirector = this.hasRole('PortalSEDirector') || this.hasRole('PortalAdmin') || this.hasRole('projects.approver');
    this.isDateAscending = true;
    this.isNameAscending = true;
    this.pageSize = 5;
    this.displayedColumns = [
      'componentName',
      'createdDate',
      'createdBy',
      'actions'
    ];
    this.adminService.getCurrentUser()
      .subscribe(res => {
        this.currentUser = res;
      });
    this.getEmployees();
    this.getTableData();
  }
  getTableData() {
    this.projectService.getTemplates()
      .subscribe(res => {
        this.serviceEstimates = res;
        this.setTableData(res);
        this.filterToMonitor();

      });

  }
  getEmployees() {
    this.adminService.getAllEmployees()
      .subscribe(httpResult => {
        this.employees = httpResult;
      });
  }
  setTableData(data: Array<any>) {
    this.dataSource = new TemplateComponentsDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
  deleteRecord(id: string) {
    this.projectService.deleteTemplate(id)
      .subscribe(res => {
        this.getTableData();

      });
  }
  cloneTemplate(id: string) {
    const dialogRef = this.dialog.open(CloningPopupComponent, {
      width: '30%',
      height: '35%',
      data: { popupFor: "Template" }
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.projectService.cloneTemplate(id, result.clonedName)
            .subscribe(res => {
              this.getTableData();

            });
        }
      });
    
  }
  sortList(sortType: string) {
    switch (sortType) {
      case 'projectName':

        break;

      case 'createdDate':

        break;
    }
  }
  sortByProjectName() {
    this.isNameAscending = !this.isNameAscending;
    if (this.isNameAscending) {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const nameOne = a.componentName.toLocaleLowerCase();
        const nameTwo = b.componentName.toLocaleLowerCase();
        if (nameOne < nameTwo) { return -1; }
        if (nameOne > nameTwo) { return 1; }
        return 1;
      }).slice(0, this.pageSize));
    } else {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const nameOne = a.componentName.toLocaleLowerCase();
        const nameTwo = b.componentName.toLocaleLowerCase();
        if (nameOne < nameTwo) { return 1; }
        if (nameOne > nameTwo) { return -1; }
        return 1;
      }).slice(0, this.pageSize));
    }
  }
  sortByDate() {
    this.isDateAscending = !this.isDateAscending;
    if (this.isDateAscending) {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const dateOne: any = new Date(a.createdDate);
        const dateTwo: any = new Date(b.createdDate);

        return dateTwo - dateOne;
      }).slice(0, this.pageSize));
    } else {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const dateOne: any = new Date(a.createdDate);
        const dateTwo: any = new Date(b.createdDate);

        return dateOne - dateTwo;
      }).slice(0, this.pageSize));
    }
  }
  hasRole(role: string) {
    return this.roleService.checkRoleExists(role, this.user, false);
  }
  selectTemplate(data: any) {
    this.selectedTemplate.emit(data);
  }
  filterToMonitor() {
    this.filterForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(
        formValue => {
          if (!this.filteredTemplates) {
            this.filteredTemplates = [];
          }

          Object.assign(this.filteredTemplates, this.serviceEstimates);
          const templateNameValue: string = formValue['templateName'];
          const selectedEmployee: string = formValue['employee'];
          if (templateNameValue) {
            this.filteredTemplates = this.filteredTemplates.filter((item: any) => {
              return item.componentName.toLocaleLowerCase().indexOf(templateNameValue.toLocaleLowerCase()) > -1;
            });
          }
          if (selectedEmployee) {
            this.filteredTemplates = this.filteredTemplates.filter((temp: any) => {
              if (temp.createdByEmployeeId) {
                return temp.createdByEmployeeId == selectedEmployee
              } else {
                return false;
              }
            });;
          }
          this.setTableData(this.filteredTemplates);
        });
  }
  resetFilters() {
    this.filterForm.controls['templateName'].setValue('');
    this.filterForm.controls['employee'].setValue('');
  }
}
