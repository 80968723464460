<form #form="ngForm" *ngIf="serviceEstimateForm && serviceEstimate" [formGroup]="serviceEstimateForm"
  (ngSubmit)="secId? openUpdateChangesDialog() :calculateTotals()">



  <mat-toolbar color="accent">
    <span>
      <button title="Save Component" mat-icon-button type="submit"
        [disabled]="serviceEstimate.isLocked || !serviceEstimateForm.valid">
        <mat-icon>save</mat-icon>
      </button>
    </span>
    <span *ngIf="secId" class="flex-wrap">
      <span *ngFor="let item of tagItems.controls;let i = index">
        <button type="button" mat-raised-button (click)="openTagPopup(tagItems.controls[i].value.tagId)"
          [ngClass]="isRemoveBtnDisabled? 'no-click' : ''" [disabled]="isRemoveBtnDisabled">
          {{getTagName(tagItems.controls[i].value.tagId)}}
          <mat-icon [ngClass]="serviceEstimate.isLocked? 'no-click' : ''" class="md-18 cursor"
            (click)="removeTagItem(i, $event)">
            clear
          </mat-icon>
        </button>
      </span>
      <span (clickOutside)="toggleSelectOpened()" appClickOutside (keydown.Tab)="toggleSelectOpened()"
        *ngIf="!serviceEstimate.isLocked">
        <button mat-icon-button matTooltip="Add Tag" class="icon-ht" (click)="toggleSelect()" type="button"
          [ngStyle]="{display: isSelectOpened? 'none' : ''}" [disabled]="tagItems.value.length == 5">
          <mat-icon>add</mat-icon>
        </button>
        <app-tag *ngIf="tags" (tagSelected)="tagSelected($event)" [actualTags]="actualTags"
          [isSelectOpened]="isSelectOpened" [tagItems]="tagItems" #tag></app-tag>
      </span>
    </span>
    <span class="fill-remaining-space"></span>
    <span>
      <button matTooltip="Component Tools" type="button" mat-icon-button [matMenuTriggerFor]="menu"
        aria-label="Component Tools">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item type="button" (click)="createTemplate()">
          <mat-icon>content_copy</mat-icon>
          <span class="menu-text">Copy to Template</span>
        </button>
        <button [disabled]="serviceEstimate.isLocked" mat-menu-item type="button" (click)="moveSEcomponent()"
          *ngIf="secId">
          <mat-icon>content_cut</mat-icon>
          <span class="menu-text">Move to Service Estimate</span>
        </button>
        <button mat-menu-item type="button" (click)="viewHistory()" *ngIf="secId">
          <mat-icon>history</mat-icon>
          <span class="menu-text">View History</span>
        </button>
      </mat-menu>
    </span>
  </mat-toolbar>

  <fieldset [disabled]="serviceEstimate.isLocked">

    <mat-spinner *ngIf="!serviceRoles"></mat-spinner>
    <div class="mat-elevation-z8 positionMiddleTop" style="width: 90%; margin: 0 auto;">

      <div *ngIf="serviceRoles">

        <mat-card style="margin: 10px 0;width: 100%;">
          <h3>
            <span *ngIf="!secId">Create</span> <span *ngIf="secId">Update</span> Service Estimate Component
          </h3>
          <div *ngIf="!serviceEstimateForm.valid">
            <h3 class="red">Check required fields.</h3>
          </div>
          <p *ngIf="!serviceEstimate.isLocked && isFixedFeeValidation()" class="red" id="bpAlert">
            Alert: BP Items in the Component Section have been updated. Please review the Component to proceed
          </p>
          <p class="text-right totalP">
            Unadjusted Totals<br>
            {{(serviceEstimateForm.get('totalAmount')['value']) | currency :'USD' :'symbol'}}<br>
            {{roundToNearestHalfhr((serviceEstimateForm.get('totalHours')['value']) - totalPrevailingWageHrs)}}
            Engineering hours(does not include prevailing wage)<br>
            {{roundToNearestHalfhr(totalBPHrs)}}
            BP hours<br>
            {{roundToNearestHalfhr(totalTravelExpenseHrs)}} Travel
            hours<br>
            {{roundToNearestHalfhr(totalPrevailingWageHrs)}} Prevailing wage hours<br>
            {{getSkuTotal() | currency :'USD' :'symbol'}} SKU Cost
          </p>
          <mat-card-content>

            <div class="row">
              <div class="col-md-4">
                <mat-form-field>
                  <mat-select *ngIf="!secId" id="serviceRole" type="text" formControlName="serviceRoleId"
                    placeholder="Service Role" required disableOptionCentering>
                    <mat-option *ngFor="let sr of serviceRoles" [value]="sr.serviceRoleId">
                      {{sr.serviceRoleName}}
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="serviceEstimateForm.controls['serviceRoleId'].hasError('required')">
                    Service
                    Role
                    is
                    required
                  </mat-error>
                  <input id="serviceRole" *ngIf="secId" [disabled]="secId" matInput placeholder="Service Role *"
                    [value]="getServiceRole()" required />
                </mat-form-field>

                <div class="row">
                  <div class="col-md-6">
                    <!-- Hourly Rate Field -->
                    <mat-form-field>
                      <input matInput [value]="selectedServiceRole?.serviceRoleHourlyRate | currency" placeholder="Hourly Rate" readonly />
                    </mat-form-field>
                  </div>
            
                  <div class="col-md-6">
                    <!-- Override Rate Field -->
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input 
                        type="string" 
                        currencyMask 
                        matInput 
                        placeholder="Override Rate" 
                        formControlName="componentOverrideRate" 
                        [readonly]="!selectedServiceRole" 
                        matTooltip="A service role is required to enter override rate"
                      />
                    </mat-form-field>                    
                  </div>
                </div>

                <mat-form-field>
                  <input id="seCompName" matInput type="text" formControlName="componentName" placeholder="Name"
                    required />

                  <mat-error *ngIf="serviceEstimateForm.controls['componentName'].hasError('required')">
                    Name
                    is
                    required
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-select *ngIf="!serviceEstimate.isLocked" id="engType" matInput formControlName="engagementType"
                    placeholder="Engagement Type" required disableOptionCentering>
                    <mat-option *ngFor="let engType of engagementType;let i = index"
                      [ngClass]="i === 1? 'hide-option' : 'show-option'" [matTooltip]=engType [value]="i">
                      {{engType}}
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="serviceEstimateForm.controls['engagementType'].hasError('required')">
                    Name
                    is
                    required
                  </mat-error>
                  <input id="engType" *ngIf="serviceEstimate.isLocked" [disabled]="serviceEstimate.isLocked" matInput
                    placeholder="Engagement Type *" [value]="getEngagementType()" />
                </mat-form-field>
                <div class="col-md-10">
                  <mat-checkbox matTooltip="If prevailing wage rates apply, check this box"
                    formControlName="hasPrevailingWage">Has Prevailing Wage?
                  </mat-checkbox>
                  <div *ngIf="serviceEstimateForm.controls['hasPrevailingWage'].value">
                    <mat-form-field *ngIf="states">
                      <mat-select placeholder="Select a State" formControlName="prevailingWageStateId"
                        disableOptionCentering>
                        <mat-option *ngFor="let state of states" [value]="state.stateId">
                          {{state.abbreviation}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-select formControlName="prevailingWageCountyId" placeholder="Select a County"
                        disableOptionCentering>
                        <mat-option *ngFor="let county of counties"
                          [value]="county.countyId">{{county.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                      <textarea matInput formControlName="prevailingWageNotes" columns="10"
                        placeholder="Prevailing Wage Notes"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <h4>Pricing Notes</h4>
                <ul>
                  <li *ngFor="let note of pricingNotes">{{note.note}}</li>

                </ul>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <div cdkDropListGroup>
          <div class="expansionGap" *ngIf="serviceEstimateForm.controls['serviceRoleId'].value" cdkDropList
            #planList="cdkDropList" id="planItems" [cdkDropListData]="serviceEstimateDetailsPlanning.value"
            [cdkDropListConnectedTo]="[executeList, closeList]" (cdkDropListDropped)="drop($event)">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h3>Planning Phase</h3>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-divider></mat-divider>
              <app-professional-services-estimate-detail-item #planItems [phaseItemType]="'standard'"
                (addPlanItem)="addItem(0);updatePhaseIndex(0)" [serviceRoles]="serviceRoles"
                [formArrayName]="'serviceEstimateDetailsPlanning'" [items]="serviceEstimateDetailsPlanning"
                [formGroup]="serviceEstimateForm" [phase]="'Plan'">
              </app-professional-services-estimate-detail-item>
            </mat-expansion-panel>
          </div>
          <div class="expansionGap" *ngIf="serviceEstimateForm.controls['serviceRoleId'].value" cdkDropList
            #executeList="cdkDropList" id="executeItems" [cdkDropListData]="serviceEstimateDetailsExecute.value"
            [cdkDropListConnectedTo]="[planList, closeList]" (cdkDropListDropped)="drop($event)">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h3>Executing Phase</h3>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-divider></mat-divider>
              <app-professional-services-estimate-detail-item [phaseItemType]="'standard'"
                (addExecuteItem)="addItem(1);updatePhaseIndex(1)" [serviceRoles]="serviceRoles"
                [formArrayName]="'serviceEstimateDetailsExecute'" [items]="serviceEstimateDetailsExecute"
                [formGroup]="serviceEstimateForm" [phase]="'Execute'">
              </app-professional-services-estimate-detail-item>
            </mat-expansion-panel>
          </div>
          <div class="expansionGap" *ngIf="serviceEstimateForm.controls['serviceRoleId'].value" cdkDropList
            #closeList="cdkDropList" id="closeItems" [cdkDropListData]="serviceEstimateDetailsClosing.value"
            [cdkDropListConnectedTo]="[planList, executeList]" (cdkDropListDropped)="drop($event)">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h3>Closing Phase</h3>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-divider></mat-divider>
              <app-professional-services-estimate-detail-item [phaseItemType]="'standard'"
                (addCloseItem)="addItem(2);updatePhaseIndex(2)" [serviceRoles]="serviceRoles"
                [formArrayName]="'serviceEstimateDetailsClosing'" [items]="serviceEstimateDetailsClosing"
                [formGroup]="serviceEstimateForm" [phase]="'Close'">
              </app-professional-services-estimate-detail-item>
            </mat-expansion-panel>
          </div>
        </div>
        <div class="col-md-12" style="padding: 0 !important;">
          <mat-accordion *ngIf="serviceRoles">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h3>Business Partner Items</h3>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-divider></mat-divider>
              <div class="row" *ngIf="businessPartnerItems.length > 0" style="width:85%;">
                <div class="col-xl-5">
                  <mat-form-field>
                    <input id="totalBPGrossMargin" matInput currencyMask placeholder="Total BP Gross Margin%"
                      formControlName="grossMarginPercentage" />
                    <span matSuffix>%</span>
                    <mat-error *ngIf="serviceEstimateForm.controls['grossMarginPercentage'].hasError('required')">
                      Total BP Gross Margin% is required
                    </mat-error>
                    <mat-error *ngIf="serviceEstimateForm.controls['grossMarginPercentage'].hasError('max')">
                      Total BP Gross Margin cannot be equal to or greater than 100 %. Please input a lower value
                    </mat-error>
                    <mat-error *ngIf="serviceEstimateForm.controls['grossMarginPercentage'].hasError('min')">
                      Total BP Gross Margin cannot be less than 0 %. Please input a higher value
                    </mat-error>
                    <!--<mat-error *ngIf="!serviceEstimateForm.controls['grossMarginPercentage'].hasError('min') && !serviceEstimateForm.controls['grossMarginPercentage'].hasError('required') && isNotValidGrossMarginPerct">
                      Alert: This SE Component Contains Non-Standard Pricing for Total BP Gross Margin%
                    </mat-error>-->
                    <mat-hint *ngIf="!isNotValidTotalGrossMarginPerct()">
                      This field represents the cumulative value for all Business Partner Items.
                    </mat-hint>
                  </mat-form-field>
                  <div class="error"
                    *ngIf="!serviceEstimateForm.controls['grossMarginPercentage'].hasError('min') && !serviceEstimateForm.controls['grossMarginPercentage'].hasError('required') && isNotValidTotalGrossMarginPerct()">
                    Alert: This SE Component Contains Non-Standard Pricing for Total BP Gross Margin%
                  </div>
                </div>
                <div class="col-xl-5">
                  <mat-form-field>
                    <input id="totalUpliftMargin" matInput currencyMask placeholder="Total Uplift Margin%"
                      formControlName="upliftPercentage" />
                    <span matSuffix>%</span>
                    <mat-error *ngIf="serviceEstimateForm.controls['upliftPercentage'].hasError('required')">
                      Total Uplift Margin% is required
                    </mat-error>
                    <mat-error *ngIf="serviceEstimateForm.controls['upliftPercentage'].hasError('min')">
                      Total Uplift Margin cannot be less than 0 %. Please input a higher value
                    </mat-error>
                    <mat-hint *ngIf="!isNotValidTotalUpliftPerct()">
                      This field represents the cumulative value for all Business Partner Items.
                    </mat-hint>
                  </mat-form-field>
                  <div class="error"
                    *ngIf="!serviceEstimateForm.controls['upliftPercentage'].hasError('min') && !serviceEstimateForm.controls['upliftPercentage'].hasError('required') && isNotValidTotalUpliftPerct()">
                    Alert: This SE Component Contains Non-Standard Pricing for Total Uplift%
                  </div>
                </div>
              </div>
              <div style="width:90%;">
                <button id="addBPIButton" style="margin-left: 15px !important;" mat-icon-button type="button"
                  (click)="addBusinessItem()" color="primary" title="Add Business Partner Item">
                  <mat-icon>add</mat-icon>
                </button>
                <div *ngIf="businessPartnerItems.length > 0" [formArrayName]="'businessPartnerItems'" class="col-md-12">
                  <div *ngFor="let item of businessPartnerItems.controls;let i = index" [formGroupName]="i"
                    [id]="'businessPartnerItem'+(i+1)">
                    <p class="header5">
                      Business Partner Item {{ i + 1}}
                      <button mat-icon-button type="button" (click)="removeControl(businessPartnerItems,i)"
                        [disabled]='serviceEstimate.isLocked'>
                        <mat-icon class="adjust-font cursor">clear</mat-icon>
                      </button>
                    </p>
                    <div class="col-xl-12">
                      <div class="row">
                        <div class="col-xl-4">
                          <input type="hidden" formControlName="businessPartnerId" />

                          <mat-form-field>
                            <input readonly type="text" matInput placeholder="Business Partner" formControlName="bpName"
                              required [id]="'businessPartnerName'+(i+1)" />
                            <span matSuffix>
                              <button mat-icon-button type="button"
                                (click)="openBusinessSearch(businessPartnerItems,i)">
                                <mat-icon>search</mat-icon>
                              </button>
                            </span>

                            <mat-hint>
                              Click the magnifying glass to select a business
                              partner
                            </mat-hint>
                            <mat-error *ngIf="businessPartnerItems.at(i).hasError('required', ['bpName'])">
                              Business Partner is required
                            </mat-error>
                          </mat-form-field>

                          <!-- <mat-checkbox formControlName="isFixedCost">Has Fixed Cost?</mat-checkbox> -->
                        </div>
                      </div>
                    </div>
                    <!--<div class="col-md-12 checkboxSpacing">
                      <mat-checkbox formControlName="isFixedCost" [id]="'hasFixedCost'+(i+1)">Has Fixed Cost?</mat-checkbox>
                    </div>-->
                    <!--<br>-->
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-xl-2">
                          <mat-form-field>
                            <input type="number" matInput placeholder="Estimated Hours" formControlName="estimatedHours"
                              required [id]="'estimatedHours'+(i+1)" />
                            <mat-error *ngIf="businessPartnerItems.at(i).hasError('required', ['estimatedHours'])">
                              Estimated
                              Hours are required
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-xl-3">
                          <mat-form-field>
                            <input matInput formControlName="activity" type="text" placeholder="Activity"
                              [id]="'activity'+(i+1)" required />
                            <mat-hint>
                              Enter a short description of BP services being
                              performed.
                            </mat-hint>
                            <mat-error *ngIf="businessPartnerItems.at(i).hasError('required', ['activity'])">
                              Activity is required
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-xl-2">
                          <mat-form-field *ngIf="solutionPilars">
                            <mat-select *ngIf="!serviceEstimate.isLocked" required placeholder="Solution Pillar"
                              disableOptionCentering [id]="'solutionPilar'+(i+1)" formControlName="solutionPilarId">
                              <mat-option *ngFor="let serviceRole of solutionPilars"
                                [value]="serviceRole.solutionPilarId">
                                {{serviceRole.solutionPilarName}}
                              </mat-option>
                            </mat-select>
                            <input [id]="'solutionPilar'+(i+1)" *ngIf="serviceEstimate.isLocked" matInput
                              placeholder="Solution Pillar *" [value]="getSolutionPilar(item)"
                              [disabled]="serviceEstimate.isLocked" />
                          </mat-form-field>

                        </div>
                        <div class="col-xl-2">
                          <mat-form-field>
                            <span matPrefix>$ </span>
                            <input currencyMask matInput placeholder="Cost" [id]="'costPrice'+(i+1)"
                              formControlName="cost" required />
                            <mat-error *ngIf="businessPartnerItems.at(i).hasError('max', ['cost'])">
                              Cost value is greater than solution pillar max charge value
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-xl-2">
                          <mat-form-field>
                            <span matPrefix>$ </span>
                            <input currencyMask matInput placeholder="Sell Price" [id]="'sellPrice'+(i+1)"
                              formControlName="sell" required />

                          </mat-form-field>
                        </div>
                        <div class="col-xl-2">
                          <mat-form-field>
                            <input matInput currencyMask placeholder="%Uplift" formControlName="upliftPercentage"
                              [id]="'upliftPerct'+(i+1)" />
                            <span matSuffix>%</span>

                          </mat-form-field>
                          <div class="error" *ngIf="isNotValidUpliftPerct(i)">
                            Alert: This SE Component contains a decrease in the Default Uplift % Value and needs
                            Approval
                          </div>
                        </div>
                        <div class="col-xl-2">
                          <mat-form-field>
                            <input [id]="'bpGrossMargin'+(i+1)" matInput currencyMask placeholder="BP Gross Margin%"
                              formControlName="bpGrossMarginPercentage" />
                            <span matSuffix>%</span>
                            <mat-error
                              *ngIf="businessPartnerItems.at(i).hasError('required', ['bpGrossMarginPercentage'])">
                              BP Gross Margin% is required
                            </mat-error>
                            <mat-error *ngIf="businessPartnerItems.at(i).hasError('max', ['bpGrossMarginPercentage'])">
                              Margin cannot be equal to or greater than 100 %. Please input a lower value
                            </mat-error>
                            <mat-error *ngIf="businessPartnerItems.at(i).hasError('min', ['bpGrossMarginPercentage'])">
                              Margin cannot be less than 0 %. Please input a higher value
                            </mat-error>
                            <!--<mat-error *ngIf="!businessPartnerItems.at(i).hasError('min', ['bpGrossMarginPercentage']) && !businessPartnerItems.at(i).hasError('required', ['bpGrossMarginPercentage']) && businessPartnerItems.at(i).hasError('isNotValid', ['bpGrossMarginPercentage'])">
                              Alert: This SE Component Contains Non-Standard Pricing for BP Gross Margin%
                            </mat-error>-->
                          </mat-form-field>
                          <div class="error"
                            *ngIf="!businessPartnerItems.at(i).hasError('min', ['bpGrossMarginPercentage']) && !businessPartnerItems.at(i).hasError('required', ['bpGrossMarginPercentage']) && isNotValidBpGrossMarginPerct(i)">
                            Alert: This SE Component contains a decrease in the Default Gross Margin % Value and needs
                            Approval
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <br>
        <div class="col-md-12" style="padding: 0 !important;">
          <mat-accordion *ngIf="serviceRoles">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h3>SKU Based Items</h3>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-divider></mat-divider>
              <app-professional-service-estimate-component-sku-item (addSkuItem)="addSkuItem()"
                [phaseItemType]="'standard'" [solutionPilars]="skuSolPilars" [formGroup]="serviceEstimateForm"
                [formArrayName]="'skuItems'" [skuItems]="skuItems" [isLocked]="serviceEstimate.isLocked">
              </app-professional-service-estimate-component-sku-item>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <br>
        <div class="col-md-12" style="padding: 0 !important;">
          <div>
            <mat-card class="customSizingCard_MI">
              <mat-card-title>
                <h3>Material Items</h3>
              </mat-card-title>
              <div class="reduced">
                <mat-divider></mat-divider>
              </div>
              <mat-card-content>
                <button style="margin-left: 15px !important;" mat-icon-button (click)="addMeterialItem()"
                  color="primary" type="button">
                  <mat-icon>add</mat-icon>
                </button>
                <div class="col-md-4" [formArrayName]="'materialItems'">
                  <div *ngFor="let item of materialItems.controls;let i = index;" [formGroupName]="i">
                    <p class="header5">
                      Item {{i + 1}}
                      <button mat-icon-button type="button" (click)="removeMaterialItem(i)"
                        [disabled]='serviceEstimate.isLocked'>
                        <mat-icon class="adjust-font cursor">clear</mat-icon>
                      </button>
                    </p>
                    <mat-form-field>
                      <mat-select placeholder="Expense Type" formControlName="materialItemType" required>
                        <mat-option
                          *ngFor="let materialItemType of materialItemsExpenseTypes"
                          [disabled]="materialItemType === 'Business Partner' && selectableBusinessPartners.length == 0"
                          [value]="materialItemType">
                          {{ materialItemType }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="item.get('materialItemType')?.value == 'Business Partner'">
                      <mat-select id="materialItemBusinessPartner" type="text"
                        formControlName="materialItemBusinessPartnerId" placeholder="Business Partner"
                        disableOptionCentering>
                        <mat-option *ngFor="let bp of selectableBusinessPartners" [value]="bp.businessPartnerId">
                          {{bp.bpName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput type="text" placeholder="Name" formControlName="name" required>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Cost" formControlName="cost" required>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Sell" formControlName="sell" required>
                    </mat-form-field>
                    <hr>
                  </div>
                </div>

              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <br>
        <div class="col-md-12" style="padding: 0 !important;">
          <mat-card class="customSizingCard_MI">
            <mat-card-title>
              <h3>Travel Expenses</h3>
            </mat-card-title>
            <div class="reduced">
              <mat-divider></mat-divider>
            </div>
            <mat-card-content>
              <button style="margin-left: 15px !important;" mat-icon-button type="button" (click)="addTravelExpense()"
                color="primary" title="Add Travel Expense">
                <mat-icon>add</mat-icon>
              </button>
              <div *ngIf="travelExpenses.length > 0" [formArrayName]="'travelExpenses'" class="travel-expense-row">
                <div *ngFor="let item of travelExpenses.controls;let i = index" [formGroupName]="i"
                  class="travel-expense-item">
                  <p class="header5">
                    Trip- {{ i + 1}}
                    <button mat-icon-button type="button" (click)="removeTEControl(travelExpenses,i)"
                      [disabled]='serviceEstimate.isLocked'>
                      <mat-icon class="adjust-font cursor">clear</mat-icon>
                    </button>
                  </p>
                  <div>
                    <div class="add-margin-top">
                      <mat-checkbox formControlName="isLocalClient" [matTooltipPosition]="'after'"
                        matTooltip="Client within 50 miles of Meridian office, travel is nonbillable">
                        Local Client
                      </mat-checkbox>
                    </div>
                    <div class="add-margin-top">
                      <mat-form-field>
                        <mat-select id="travelExpenseType" type="text" formControlName="travelExpenseType"
                          placeholder="Expense Type" required disableOptionCentering
                          (selectionChange)="onTravelExpenseTypeChanged(i)">
                          <mat-option *ngFor="let travelExpenseType of travelExpenseTypes" [value]="travelExpenseType" [disabled]="travelExpenseType === 'Business Partner' && selectableBusinessPartners.length == 0">
                            {{travelExpenseType}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                        <mat-form-field *ngIf="item.get('travelExpenseType')?.value == 'Business Partner'">
                        <mat-select id="travelExpenseBusinessPartner" type="text"
                          formControlName="travelExpenseBusinessPartnerId" placeholder="Business Partner" required
                          disableOptionCentering>
                          <mat-option *ngFor="let bp of selectableBusinessPartners" [value]="bp.businessPartnerId">
                            {{bp.bpName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Airfare" formControlName="airfare" />
                    </mat-form-field>
                    <mat-form-field>
                      <textarea matInput placeholder="Airfare Notes" formControlName="airfareNotes"></textarea>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Auto" formControlName="auto" />
                    </mat-form-field>
                    <mat-form-field>
                      <textarea matInput placeholder="Auto Notes" formControlName="autoNotes"></textarea>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Hotel" formControlName="hotel" />
                    </mat-form-field>
                    <mat-form-field>
                      <textarea matInput placeholder="Hotel Notes" formControlName="hotelNotes"></textarea>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Gas" formControlName="gas" />
                    </mat-form-field>
                    <mat-form-field>
                      <textarea matInput placeholder="Gas Notes" formControlName="gasNotes"></textarea>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Meals" formControlName="meals" />
                    </mat-form-field>
                    <mat-form-field>
                      <textarea matInput placeholder="Meals Notes" formControlName="mealNotes"></textarea>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Other/Misc" formControlName="other" />
                    </mat-form-field>
                    <mat-form-field>
                      <textarea matInput placeholder="Other/Misc Notes" formControlName="otherNotes"></textarea>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput type="number" placeholder="Travel Hours" formControlName="travelHours" />
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Per Hour Charge" formControlName="perHourCharge" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <br>
        <div class="col-md-12" style="padding: 0 !important;">

          <mat-card class="customSizingCard_MI">
            <mat-card-title>
              <h3>Additional Items</h3>
            </mat-card-title>
            <div class="reduced">
              <mat-divider></mat-divider>
            </div>
            <mat-card-content>
              <div class="col-md-4">
                <mat-form-field color="warn">
                  <span matPrefix>$&nbsp;</span>
                  <input matInput currencyMask placeholder="Price Adjustment" formControlName="priceAdjustment" />
                </mat-form-field>
                <mat-form-field>
                  <textarea matInput formControlName="notes" placeholder="Notes" rows="10"
                    [required]="isNotesRequired"></textarea>
                  <mat-hint>
                    ex: on-site or remote work, price adjustments, travel details, etc.
                  </mat-hint>
                  <mat-error *ngIf="serviceEstimateForm.controls['notes'].hasError('required')">
                    Notes
                    are
                    required for Price Adjustment
                  </mat-error>
                </mat-form-field>
                <!-- <mat-form-field>
                    <span matPrefix>$&nbsp;</span>
                    <input matInput readonly placeholder="Total" formControlName="totalAmount" />
                </mat-form-field> -->
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div *ngIf="!isValid">
          <h3 class="red-text">Check required fields.</h3>
        </div>
      </div>
    </div>

  </fieldset>
</form>
<a #donwloadCsvLink></a>
