<mat-card *ngIf="serviceRoleFormGroup && projectControls && approvers">
  <form [formGroup]="serviceRoleFormGroup" (ngSubmit)="submitForm()">
    <mat-card-content>
      <div class="row">
        <mat-form-field>
          <input matInput type="text" formControlName="serviceRoleName" placeholder="Name" id="serviceRoleName" />
          <mat-error *ngIf="serviceRoleFormGroup.controls['serviceRoleName'].hasError('required')">
            A name is required.
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" formControlName="serviceRoleDescription" placeholder="Description" id="serviceRoleDescription" />
          <mat-error *ngIf="serviceRoleFormGroup.controls['serviceRoleDescription'].hasError('required')">
            A description is
            required.
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <span matPrefix>$ </span>
          <input currencyMask matInput formControlName="serviceRoleHourlyRate" placeholder="Hourly Rate" id="serviceRoleHourlyRate" />
          <mat-error *ngIf="serviceRoleFormGroup.controls['serviceRoleHourlyRate'].hasError('required')">
            A hourly rate is
            required.
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Select a project control" formControlName="projectControlId" id="projectControlId" disableOptionCentering>
            <mat-option *ngFor="let control of projectControls" [value]="control.projectControlId">
              {{control.projectControlName}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="serviceRoleFormGroup.controls['projectControlId'].hasError('required')">
            A project control is
            required.
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-select id="serviceEstimateType" multiple placeholder="Select Service"
                              disableOptionCentering formControlName="serviceRoleEstimateTypes">
                              <mat-option [value]="0">Fixed Fee</mat-option>
                              <mat-option [value]="1">Time & Materials</mat-option>
                              <mat-option [value]="3">Managed Services</mat-option>
                              <mat-option [value]="2">Misc</mat-option>
                  </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" formControlName="epicorPartNumberId" placeholder="Epicor Part Number" id="epicorPartNumberId" />
          <mat-error *ngIf="serviceRoleFormGroup.controls['epicorPartNumberId'].hasError('required')">
            An Epicor Part Number is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4" [formArrayName]="'employeeServiceRoles'">
        <button mat-icon-button (click)="addApprover()" type="button" id="addApproverBtn">
          <mat-icon>add</mat-icon>
        </button>
        <div *ngFor="let item of employeeServiceRoles?.controls;let i = index;" [formGroupName]="i">
          <p class="header5">
            Approver {{i + 1}}
            <mat-icon class="md-18 cursor" (click)="removeApprover(i)">clear</mat-icon>
          </p>
          <mat-form-field>
            <mat-select placeholder="Select a approver" formControlName="employeeId" [id]="'approver'+(i+1)" disableOptionCentering>
              <mat-option *ngFor="let control of approvers" [value]="control.employeeId" [disabled]="isApproverDisabled(control.employeeId)">
                {{control.firstName + ' ' + control.lastName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="employeeServiceRoles.at(i).hasError('required', ['employeeId'])">
              A approver is
              required.
            </mat-error>
          </mat-form-field>
          <!-- <mat-checkbox formControlName="isResourceAssigner">Is resource assigner?</mat-checkbox> -->
          <hr>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" [disabled]="!serviceRoleFormGroup.valid" id="saveBtn">Save</button>
    </mat-card-actions>
  </form>
</mat-card>
