import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceEstimateTotalCost } from './service-estimates/_models/service-estimate-totalcost.model';
import { ServiceEstimateSelectModel } from './service-estimates/_models/service-estimate-select-list.model';

@Injectable()
export class ProfessionalServicesService {

  constructor(private http: HttpClient, private router: Router) { }
  cloneToTemplate(id: any) {
    return this.http.get(`/api/ServiceEstimates/createTemplate/${id}`);
  }

  createProjectDocument(projectDocument: any) {
    const body: FormData = new FormData();
    body.append('documentFile', projectDocument.documentFile, projectDocument.name);
    body.append('projectDocumentType', projectDocument.vehicleExpenseDescription);
    body.append('name', projectDocument.vehicleExpenseCost.toString());
    body.append('serviceProjectId', projectDocument.vehicleExpenseType.toString());

    return this.http.post(`/api/ProjectDocuments`, body);
  }
  updateAllResourcesSigned(id: any, isAllResourcesAssigned: any) {
    const body = { isAllResourcesAssigned };
    return this.http.put<any>(`/api/ServiceEstimateComponents/${id}/UpdateResourcesAssigned`, body);
  }

  convertToCsv(jsonToConvert: string, customHeaders: string[] = []): string {
    const jsonItems = JSON.parse(jsonToConvert);
    const items = jsonItems;
    const replacer = (key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here

    // Determine the header either from custom headers or from the object keys
    const header = customHeaders.length > 0 ? customHeaders : Object.keys(items[0]);

    let csv = items.map((row: any) => {
      const value = header.map(fieldName => {
        // Check if the fieldName is a custom header, then map it to the correct key in the object
        const key = customHeaders.length > 0 ? Object.keys(items[0])[customHeaders.indexOf(fieldName)] : fieldName;
        let convertedString = JSON.stringify(row[key], replacer);
        convertedString = convertedString.replace(/"/g, '');
        return convertedString;
      }).join(',');
      return value;
    });

    if (customHeaders.length === 0) {
        // If no custom headers, normalize the headers
        const normalizeHeaders: Array<string> = [];
        header.forEach(h => {
            normalizeHeaders.push(h.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }).replace('"', ''));
        });
        csv.unshift(normalizeHeaders.join(','));
    } else {
        // If custom headers are provided, use them directly
        csv.unshift(customHeaders.join(','));
    }

    csv = csv.join('\r\n');
    return csv;
  }

  convertToCsvReports(jsonToConvert: string) {
    const jsonItems = JSON.parse(jsonToConvert);
    const items = jsonItems;
    const replacer = (key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    let csv = items.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    const normalizeHeaders: Array<string> = [];
    header.forEach(h => {
      normalizeHeaders.push(h.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }));
    });
    csv.unshift(normalizeHeaders.join(','));
    csv = csv.join('\r\n');
    return csv;
  }

  downloanComponentActivities(seId: string) {
    return this.http.get<Array<any>>(`/api/ServiceEstimates/${seId}/activities`);
  }
  runApprovesServiceEstimatesReport(query: any) {
    var body = JSON.stringify(query);
    return this.http.post<any[]>(`/api/Reports/ApprovedServiceEstimates`, body);
  }

  runCsvExport(seIds: any) {
    const body = JSON.stringify(seIds);
    console.log('body', body);
    return this.http.post<any[]>(`/api/ServiceEstimates/export`, body, {
        headers: { 'Content-Type': 'application/json' }
    });
}


  getServiceEstimate(id: string) {
    return this.http.get(`/api/ServiceEstimates/${id}/noncomplex`);
  }
  getProjects() {
    return this.http.get<any[]>(`/api/ServiceProjects`);
  }
  getAssignedProjects() {
    return this.http.get<any[]>(`/api/ServiceProjects/assignedprojects`);
  }
  getProject(id: string) {
    return this.http.get<any>(`/api/ServiceProjects/${id}`);
  }
  getCloudMigrationServiceProject(id: string) {
    return this.http.get(`/api/CloudMigrationServiceProjects/${id}`);
  }
  assignEmployeeToServiceProjects(assignedEmployee: any) {
    //const data = { serviceProjectId: serviceProjectId, employeeId: employeeId };
    const body = JSON.stringify(assignedEmployee);
    return this.http.post(`/api/ServiceProjectsAssignedEmployees`, body);
  }
  getEmployees() {
    return this.http.get<any[]>(`/api/Employees`);
  }
  getActiveEmployees() {
    return this.http.get<any[]>(`/api/admin/employees`);
  }
  unassignEmployeeFromServiceProject(id: string) {
    return this.http.delete(`/api/ServiceProjectsAssignedEmployees/${id}`);
  }
  getAssignedEmployeesByProjectNumber(servicepRojectNumber: string) {
    return this.http.get<any[]>(`/api/ServiceProjectsAssignedEmployees/serviceProjects/${servicepRojectNumber}`);
  }
  createProject(project: any) {
    const body = JSON.stringify(project);
    return this.http.post<any>(`/api/serviceProjects`, body);
  }

  updateProject(project: any) {
    const body = JSON.stringify(project);
    return this.http.put(`/api/serviceProjects/${project.id}`, body);
  }
  deleteProject(id: string) {
    return this.http.delete(`/api/ServiceProjects/${id}`);
  }
  getSEsByProjectId(projectId: string) {
    return this.http.get<any[]>(`/api/ServiceProjects/${projectId}/ServiceEstimates`);
  }
  getSEApproversByProjectId(projectId: string) {
    return this.http.get<any[]>(`/api/ServiceProjects/${projectId}/ServiceEstimatesApprovers`);
  }
  getSEs() {
    return this.http.get(`/api/ServiceEstimates`);
  }
  getSE(id: string, isSE: boolean) {
    return this.http.get<any>(`/api/ServiceEstimates/${id}/${isSE}`);
  }

  getServiceEstimateTotalCost(id: string): Observable<ServiceEstimateTotalCost> {
    return this.http.get<ServiceEstimateTotalCost>(`/api/ServiceEstimates/${id}/estimatiedPrice`);
  }
  createSE(project: any) {
    const body = JSON.stringify(project);
    return this.http.post(`/api/ServiceEstimates`, body);
  }
  approveSe(seId: any, serviceEstimate: any) {
    serviceEstimate['serviceEstimateUrl'] = window.location.href;
    const body = JSON.stringify(serviceEstimate);
    return this.http.put(`/api/ServiceEstimates/${seId}/approve`, body);
  }
  finalApproveSe(seId: any, serviceEstimate: any) {
    serviceEstimate['serviceEstimateUrl'] = window.location.href;
    const body = JSON.stringify(serviceEstimate);
    return this.http.put(`/api/ServiceEstimates/${seId}/Finalapprove`, body);
  }

  updateSE(project: any) {
    const body = JSON.stringify(project);
    return this.http.put(`/api/ServiceEstimates/${project.serviceEstimateId}`, body);
  }

  sendSeForApproval(project: any) {

    project['serviceEstimateUrl'] = window.location.href;
    const body = JSON.stringify(project);

    return this.http.put(`/api/ServiceEstimates/${project.serviceEstimateId}/sendForApproval`, body);
  }

  calcPMOverride(id: string, overrideField: any) {
    const body = JSON.stringify(overrideField);

    return this.http.put(`/api/ServiceEstimates/${id}/calcPMOverride`, body);
  }

  deleteSE(id: string) {
    return this.http.delete(`/api/ServiceEstimates/${id}`);
  }
  markContractSigned(serviceEstimate: any) {
    var body = JSON.stringify(serviceEstimate);
    return this.http.put(`/api/ServiceEstimates/${serviceEstimate.serviceEstimateId}/markcontractsigned`, body);
  }

  updateContractSignedDate(serviceEstimate: any) {
    var body = JSON.stringify(serviceEstimate);
    return this.http.put(`/api/ServiceEstimates/${serviceEstimate.serviceEstimateId}/updateContractSignedDate`, body);
  }

  deleteTemplateBusinessPartnerItemById(id: string) {
    return this.http.delete(`/api/TemplateItems/${id}/businessPartnerItem`);
  }
  deleteMaterialItemById(id: string) {
    return this.http.delete(`/api/TemplateItems/${id}/materialItem`);
  }
  deletePricingNoteById(id: string) {
    return this.http.delete(`/api/pricingnotes/${id}`);
  }

  getSEComponentsBySeId(id: string) {
    return this.http.get<any>(`/api/ServiceEstimates/${id}/ServiceEstimateComponents`);
  }
  getSEChangeOrdersBySeId(id: string) {
    return this.http.get<any>(`/api/ServiceEstimates/${id}/ChangeOrderComponents`);

  }
  getSEComponentsPendingApproval() {
    return this.http.get<any>(`/api/ServiceEstimateComponents/PendingApproval`);
  }
  getSEComponentsPendingRateApproval() {
    return this.http.get<any>(`/api/ServiceEstimateComponents/PendingRateApproval`);
  }
  getServiceEstimatesPendingPMOApproval() {
    return this.http.get<any>(`/api/ServiceEstimates/PendingPMOApproval`);
  }
  getServiceEstimatesPendingThresholdApproval() {
    return this.http.get<any>(`/api/ServiceEstimates/PendingThresholdApproval`);
  }
  getSEComponentsApproved() {
    return this.http.get<any>(`/api/ServiceEstimateComponents/Approved`);
  }
  createSeComponent(seComponent: any) {
    const body = JSON.stringify(seComponent);
    return this.http.post<any>(`/api/ServiceEstimateComponents`, body);
  }
  createChangeOrderComponent(seComponent: any) {
    const body = JSON.stringify(seComponent);
    return this.http.post(`/api/ChangeOrderComponents`, body);
  }
  updateSeComponent(seComponent: any) {
    const body = JSON.stringify(seComponent);
    return this.http.put<any>(`/api/ServiceEstimateComponents/${seComponent.id}`, body);
  }
  updateChangeOrderComponent(seComponent: any) {
    const body = JSON.stringify(seComponent);
    return this.http.put(`/api/ChangeOrderComponents/${seComponent.id}`, body);
  }
  approveChangeOrderComponent(seComponent: any) {
    const body = JSON.stringify(seComponent);
    return this.http.put(`/api/ChangeOrderComponents/${seComponent.id}/approve`, body);
  }
  approveServiceEstimateComponent(seComponent: any) {
    const body = JSON.stringify(seComponent);
    return this.http.put(`/api/ServiceEstimateComponents/${seComponent.id}/approve`, body);
  }
  rejectServiceEstimateComponent(seComponent: any) {
    const body = JSON.stringify(seComponent);
    return this.http.put(`/api/ServiceEstimateComponents/${seComponent.id}/reject`, body);
  }
  rejectCOComponent(seComponent: any) {
    const body = JSON.stringify(seComponent);
    return this.http.put(`/api/ChangeOrderComponents/${seComponent.id}/reject`, body);
  }
  sendServiceEstimateComponentForApproval(comp: any) {
    const body = JSON.stringify(comp);
    return this.http.put(`/api/ServiceEstimateComponents/${comp.id}/sendforapproval`, body);
  }
  sendChangeOrderComponentForApproval(comp: any) {
    const body = JSON.stringify(comp);
    return this.http.put(`/api/ChangeOrderComponents/${comp.id}/sendforapproval`, body);
  }
  getSECComponentById(secId: string) {
    return this.http.get<any>(`/api/ServiceEstimateComponents/${secId}`);
  }
  getChangeOrderComponentById(secId: string) {
    return this.http.get<any>(`/api/ChangeOrderComponents/${secId}`);
  }
  deleteSEC(id: string) {
    return this.http.delete(`/api/ServiceEstimateComponents/${id}`);
  }
  cloneSE(id: string, seName: string) {
    return this.http.get(`/api/ServiceEstimates/${id}/${seName}/clone`);
  }
  deleteChangeOrder(id: string) {
    return this.http.delete(`/api/ChangeOrderComponents/${id}`);
  }
  cloneChangeOrderComponentById(secId: string) {
    return this.http.get(`/api/ChangeOrderComponents/${secId}/clone`);
  }

  cloneSEComponentsById(id: string, secName: string) {
    return this.http.get(`/api/ServiceEstimateComponents/${id}/${secName}/clone`);
  }
  createTemplate(templateComponent: any) {
    const body = JSON.stringify(templateComponent);
    return this.http.post(`/api/TemplateComponents`, body);
  }
  updateTemplate(templateComponent: any) {
    const body = JSON.stringify(templateComponent);
    return this.http.put(`/api/TemplateComponents/${templateComponent.id}`, body);
  }
  getTemplates() {
    return this.http.get<any[]>(`/api/TemplateComponents`);
  }
  getTemplate(id: string) {
    return this.http.get<any>(`/api/TemplateComponents/${id}`);
  }
  deleteTemplate(id: string) {
    return this.http.delete(`/api/TemplateComponents/${id}`);
  }
  cloneTemplate(id: string, templateName: string) {
    return this.http.get(`/api/TemplateComponents/${id}/${templateName}/clone`);
  }
  createChangeOrderFromTemplate(id: string, serviceEstimateId: string) {
    return this.http.put(`/api/TemplateComponents/${id}/cloneChangeOrder/${serviceEstimateId}`, null);
  }
  createServiceEstimateFromTemplate(id: string, serviceEstimateId: string) {
    return this.http.put(`/api/TemplateComponents/${id}/cloneServiceEstimate/${serviceEstimateId}`, null);
  }

  attachServiceEstimateComponent(id: string) {
    return this.http.get(`/api/ServiceEstimateComponents/${id}/attach`);
  }

  detachServiceEstimateComponent(id: string) {
    return this.http.get(`/api/ServiceEstimateComponents/${id}/detach`);
  }
  attachChangeOrderComponentById(secId: string) {
    return this.http.get(`/api/ChangeOrderComponents/${secId}/attach`);
  }
  detachChangeOrderComponent(secId: string) {
    return this.http.get(`/api/ChangeOrderComponents/${secId}/detach`);
  }
  sendEmail(emailAddress: any) {
    const body = JSON.stringify(emailAddress);
    return this.http.put(`/api/email`, body);
  }
  getServiceEstimateSummary(serviceEstimateId: string) {
    return this.http.get<any>(`/api/ServiceEstimateSummaries/ServicesEstimates/${serviceEstimateId}/generate`);
  }

  recalculateServiceEstimateSummary(summaryId: number, serviceEstimateId: string) {
    return this.http.get(`/api/ServiceEstimateSummaries/${summaryId}/ServiceEstimates/${serviceEstimateId}/recalculate`);
  }

  updateServiceEstimateSummary(summaryId: number, summary: any) {
    const body = JSON.stringify(summary);
    return this.http.put(`/api/ServiceEstimateSummaries/${summaryId}`, body);
  }
  checkIfFinalApprover(seId: any) {
    return this.http.get<any>(`/api/ApproverThresholds/serviceEstimates/${seId}`);
  }
  moveComponentToSE(data: any, componentId: any) {
    const seId = data.serviceEstimateId;
    return this.http.put(`/api/ServiceEstimates/${seId}/MoveComponents/${componentId}`, null);
  }
  fetchSEMilestones(projectId: string, milestoneId: number) {
    return this.http.get<any>(`/api/ServiceEstimates/${projectId}/SEMileStones/${milestoneId}`);
  }
  getCurrencies() {
    return this.http.get<any>(`/api/CurrencyConversion/Currencies`);
  }
  getConversionRate(targetCurrency: string, currentCurrency: string, createdOn: string) {
    return this.http.get<any>(`/api/CurrencyConversion/ConverstionRate?TargetCurrency=${targetCurrency}&CurrenctCurrency=${currentCurrency}&createdOn=${createdOn}`);
  }
  getCurrencyByConversionRate(currencyConversionRateId: string) {
    return this.http.get<any>(`/api/CurrencyConversion/${currencyConversionRateId}/currency`);
  }
  CheckTemplateNameExists(templateName: string, id?: string) {
    return id ? this.http.get<boolean>(`/api/TemplateComponents/${templateName}/checkNameExists?id=${id}`) : this.http.get<boolean>(`/api/TemplateComponents/${templateName}/checkNameExists`);
  }
  CheckSENameExists(seName: string, id?: string) {
    return id ? this.http.get<boolean>(`/api/ServiceEstimates/${seName}/checkNameExists?id=${id}`) : this.http.get<boolean>(`/api/ServiceEstimates/${seName}/checkNameExists`);
  }
  CheckSECNameExists(secName: string, id?: string) {
    return id ? this.http.get<boolean>(`/api/ServiceEstimateComponents/${secName}/checkNameExists?id=${id}`) : this.http.get<boolean>(`/api/ServiceEstimateComponents/${secName}/checkNameExists`);
  }
}
